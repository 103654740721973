import React from 'react'

import Link from 'src/components/general/Link/Link'

import styles from './Menu.scss'

const Menu = () => (
  <div id='MainNavigation' className={styles['main-navigation']}>
    <div styleName='container'>
      <div className={styles['nav-label']}>
        {/* <a id='openMenuBtn' className={styles.icon + ' ' + styles.burger}> */}
        {/* &nbsp; */}
        {/* </a> */}

        <Link className={styles.logo} to='/'>
          <img src='' alt='' />
          <label>Pressbyrån</label>
          <img src='/assets/images/orb.png' className={styles.orb} alt='' />
        </Link>
      </div>
    </div>
  </div>
)

export default Menu
