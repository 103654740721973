import { ofType } from 'redux-observable'
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import * as Observable from 'rxjs'
import { ajax } from 'rxjs/ajax'
import * as R from 'ramda'
import { dotPath } from '../functions/object/dotPath'
import ifElse from '../functions/misc/ifElse'
import isUndefined from '../functions/boolean/isUndefined'

const onCartAction = response => ({
  type: 'added_to_basket',
  payload: response.cart
})
const onFailAction = response => ({
  type: 'added_to_basket_failed',
  payload: response
})

const onCartResponse = response =>
  ifElse(R.prop('success'), onCartAction, onFailAction, response)

export default (action$, state$) =>
  action$.pipe(
    ofType('update_cart'),
    withLatestFrom(state$), // Get latest state
    mergeMap(([action, state]) => {
      const apiUrl = dotPath('siteData.basketApi')(state)
      const orderNumber = dotPath('cart.ecommerceCart.number')(state)
      const payload = dotPath('payload')(action)

      const orderObject = isUndefined(orderNumber) ? {} : { orderNumber } // use this hack to append object only if defined

      return Observable.concat(
        Observable.of({
          type: 'pending_request',
          payload: {
            url: `${apiUrl}`,
            fetching: 'cart',
            data: { ...payload, ...orderObject }
          }
        }),
        ajax
          .post(
            `${apiUrl}`,
            { ...payload, ...orderObject },
            { Accept: 'application/json' }
          )
          .pipe(
            map(ajaxResponse => onCartResponse(ajaxResponse.response)),
            catchError(error =>
              Observable.of({
                type: 'add_to_basket_error',
                payload: (console.log(error), error.xhr.response),
                error: true
              })
            )
          )
      )
    })
  )
