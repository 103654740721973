import {
  add,
  always,
  assoc,
  compose,
  dissoc,
  dissocPath,
  identity,
  mergeDeepLeft,
  mergeDeepRight,
  mergeDeepWithKey,
  omit,
  prop
} from 'ramda'

import createReducer from 'src/functions/misc/createReducer'
import ifElse from '../functions/misc/ifElse'

const ifElseThenSame = (cond, truthy, val) =>
  ifElse(cond, truthy, identity, val)

const initialState = { items: {} }
export default createReducer(initialState)({
  add_to_basket: (payload, state) => {
    const addOnMerge = (k, l, r) => (k === 'amount' ? add(l, r) : r)
    return ifElseThenSame(
      payload.purchasableId,
      mergeDeepWithKey(addOnMerge, state, {
        items: { [payload.purchasableId]: payload }
      }),
      state
    )
  },
  added_to_basket: (payload, state) =>
    compose(
      dissoc('errors'),
      assoc('ecommerceCart', payload)
    )(state),
  checkout_snippet_failed: mergeDeepLeft,
  update_basket: (payload, state) => {
    return ifElseThenSame(
      payload.purchasableId,
      mergeDeepRight(state, {
        items: { [payload.purchasableId]: payload }
      }),
      state
    )
  },
  remove_from_basket: (payload, state) => {
    return Object.assign({}, state, {
      items: omit([payload.purchasableId], state.items)
    })
  },
  added_to_basket_failed: (payload, state) =>
    compose(
      assoc('cart', prop('cart', payload)),
      assoc('errors', prop('errors', payload))
    )(state),
  delete_basket: always(initialState),
  clear_coupon_error: dissocPath(['errors', 'couponCode'])
  // added_to_basket: identity
})
