import { apply, __, prop } from 'ramda'

import isFunction from 'src/functions/boolean/isFunction'
import notNil from 'src/functions/boolean/notNil'
import ifElse from './ifElse'

export default (initialState = null) => actionTypes => (
  state = initialState,
  { type, payload }
) => {
  const args = ifElse(notNil(payload), [payload, state], [state])
  const applyFn = apply(__, args)
  const fn = () => prop(type, actionTypes)

  return ifElse(isFunction, applyFn, state, fn)
}
