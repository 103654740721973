import { ofType } from 'redux-observable'
import {
  filter,
  map,
  mergeMap,
  withLatestFrom,
  catchError
} from 'rxjs/operators'
import * as Observable from 'rxjs'
import { ajax } from 'rxjs/ajax'
import * as R from 'ramda'
import hash from 'object-hash'
import ifElse from '../functions/misc/ifElse'
import isObject from '../functions/boolean/isObject'
import isArray from '../functions/boolean/isArray'

const getHash = props => {
  const { path, method, data } = props
  return hash({ path, method, data })
}

// const returnJson = (r) => r.json()
// const returnDirect = (r) => r

// const api = {
//   fetch: (payload) => {
//     const fetchData = R.mergeLeft({ method: payload.method }, ifElse(R.isEmpty, {}, (data) => ({
//       // headers: {
//       //   'Content-Type': 'application/json'
//       // },
//       // body: new FormData(R.map(JSON.stringify)(data))
//     }), payload.data))
//     console.log('Fetching', payload, payload.path, fetchData)
//     const request = fetch(payload.path, fetchData,R.map(JSON.stringify)(payload.data))
//       .then(response => {
//         try {
//           return response.json()
//         } catch (e) {
//           return e.message
//         }
//         return R.tryCatch(returnJson, returnDirect)(response)
//       })
//       .catch(error => console.error('Error:', error))
//     return Observable.from(request)
//   }
// }
const api = {
  fetch: payload => {
    const method = ifElse(
      R.toLower(payload.method) === 'get',
      () => ajax.get,
      () => ajax.post
    )
    return method(
      payload.path,
      R.map(
        R.curry(ifElse)(R.either(isObject, isArray), JSON.stringify, R.identity)
      )(payload.data)
    )
  }
}
const isPayloadInState = ([action, state]) =>
  R.pipe(
    R.prop('fetched'),
    R.has(getHash(action.payload)),
    R.not
  )(state)

export default (action$, state$) =>
  action$.pipe(
    ofType('fetch'),
    withLatestFrom(state$), // Get latest state
    filter(isPayloadInState),
    mergeMap(([action, state]) =>
      api.fetch(action.payload).pipe(
        // This returns our Observable wrapping the Promise
        map(response => ({
          type: 'fetched',
          payload: { fetch: action.payload, response: response.response }
        })),
        catchError(error =>
          Observable.of({
            type: 'fetch_error',
            payload: (console.log(error), error.xhr.response),
            error: true
          })
        )
      )
    )
  )
