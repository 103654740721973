import React, { Component } from 'react'

import { Router } from '@reach/router'
import DynamicRoute from './DynamicRoute'

class DynamicRoutes extends Component {
  static = {}

  render () {
    const { dynamicRoutes, parentData } = this.props
    const dynamicComponents = dynamicRoutes.map((dynamicRoute, i) => {
      const { component: comp, path, ...routeProps } = dynamicRoute
      const p = { path, parentData, comp, routeProps }
      return <DynamicRoute key={'d' + i} {...p} />
    })
    return <Router>{dynamicComponents}</Router>
  }
}

export default DynamicRoutes
