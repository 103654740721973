import { always, assoc } from 'ramda'

import createReducer from 'src/functions/misc/createReducer'

const initialState = {
  pickupTime: '',
  storeData: {}
}

const reducer = {
  set_pickup_time: assoc('pickupTime'),
  add_store_data: assoc('storeData'),
  delete_basket: always(initialState)
}

export default createReducer(initialState)(reducer)
