import { createStore, applyMiddleware, compose } from 'redux'
import logger from 'redux-logger'
import { createEpicMiddleware } from 'redux-observable'
import { fromEvent, merge } from 'rxjs'
import { map, distinctUntilChanged } from 'rxjs/operators'
import { persistStore } from 'redux-persist'
import { createMiddleware } from 'redux-beacon'
// import GoogleAnalytics, { trackPageView } from '@redux-beacon/google-analytics';
import GoogleTagManager from '@redux-beacon/google-tag-manager'

import { globalHistory } from '@reach/router'

import reducers from 'src/reducers/reducers'
import epics from 'src/epics/epics'
import { getBreakPointFromWidth } from '../helpers/breakpoint'
import { dotPath } from '../functions/object/dotPath'
import * as R from 'ramda'

const isBrowser = typeof window !== 'undefined'

const composeEnhancers =
  (isBrowser && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const epicMiddleware = createEpicMiddleware()

const getGTMProductsFromCart = cart => {
  const lineItems = dotPath('ecommerceCart.lineItems')(cart)
  if (!lineItems) {
    return []
  }
  return Object.values(lineItems)
    .filter(lineItem => {
      const item = dotPath(`items.${lineItem.purchasableId}`)(cart)
      const product = lineItem.product || item.product
      return item && product
    })
    .map(lineItem => {
      const item = dotPath(`items.${lineItem.purchasableId}`)(cart)
      const product = lineItem.product || item.product
      return {
        //  adding a product to a shopping cart.
        id: product.sku,
        price: lineItem.price || item.price || product.price,
        name: product.title,
        category: item.product.categoryId,
        variant: item.product.priceGroup,
        quantity: Math.abs(dotPath('amount')(item))
      }
    })
}
const getGTMProductsFromLineItems = lineItems => {
  if (!lineItems) {
    return []
  }
  return Object.values(lineItems)
    .filter(i => !!i.product)
    .map(lineItem => {
      const product = lineItem.product
      return {
        //  adding a product to a shopping cart.
        id: product.sku,
        quantity: lineItem.qty
      }
    })
}

const getGTMEventType = R.cond([
  [R.propEq('viewType', 'checkout'), R.always('checkout')],
  [R.propEq('viewType', 'payment'), R.always('checkout')],
  [R.propEq('viewType', 'purchase'), R.always('purchase')]
])

const getGTMStep = R.cond([
  [R.propEq('viewType', 'checkout'), R.always(1)],
  [R.propEq('viewType', 'payment'), R.always(2)],
  [R.propEq('viewType', 'purchase'), R.always(3)]
])

/* Google Tag Manger Middleware */
const eventsMap = {
  history_change: action => ({
    event: 'pageView',
    page: action.payload.location.pathname
  }),
  basket_view: action => ({
    event: 'updateEcommerce',
    ecommerce: {
      [getGTMEventType(action.payload)]: {
        actionField: {
          step: getGTMStep(action.payload),
          option: dotPath('state.selectedData.storeData.title')(action.payload)
        },
        products: getGTMProductsFromCart(dotPath('state.cart')(action.payload))
      }
    }
  }),
  view_order: action => ({
    event: 'updateEcommerce',
    ecommerce: {
      purchase: {
        checkout: {
          actionField: {
            id: dotPath('payload.order.number')(action), // dotPath('state.cart.items')(action.payload), // Transaction ID. Required for purchases and refunds.
            affiliation: dotPath('payload.order.storeId')(action),
            revenue: dotPath('payload.order.totalPrice')(action), // Total transaction value (incl. tax and shipping)
            tax: dotPath('payload.order.totalTaxIncluded')(action),
            shipping: dotPath('payload.order.totalShippingCost')(action),
            couponCode: dotPath('payload.order.couponCode')(action)
          },
          products: getGTMProductsFromLineItems(
            dotPath('order.lineItems')(action.payload)
          )
        }
      }
    }
  }),
  add_to_basket: (action, state) => {
    const product =
      action.payload.product ||
      dotPath(`cart.items.${action.payload.purchasableId}.product`)(state)
    if (!product) {
      console.error('No product in payload or state')
      return false
    }
    return {
      event: 'updateEcommerce',
      ecommerce: {
        currencyCode: 'SEK',
        [dotPath('amount')(action.payload) > 0 ? 'add' : 'remove']: {
          // 'add' or remove actionFieldObject measures.
          products: [
            {
              //  adding a product to a shopping cart.
              id: product.sku,
              price: product.price,
              name: product.title,
              category: product.categoryId,
              variant: product.priceGroup,
              quantity: Math.abs(dotPath('amount')(action.payload))
            }
          ]
        }
      },
      payload: action.payload,
      product: product,
      quantity: action.payload.amount
    }
  },
  gtmEvent: action => action.payload
}

// Create or import an events map.
// See "getting started" pages for instructions.

const options = {
  // dataLayerName: /* (optional) string */,
}

const gtmWithOptions = GoogleTagManager(options)
const gaMiddleware = createMiddleware(eventsMap, gtmWithOptions)

const appliedMiddleware = isBrowser
  ? applyMiddleware(logger, epicMiddleware, gaMiddleware)
  : applyMiddleware(epicMiddleware, gaMiddleware)

const reduxStore = createStore(reducers, composeEnhancers(appliedMiddleware))

globalHistory.listen(data => {
  reduxStore.dispatch({
    type: 'history_change',
    payload: data
  })
})

if (isBrowser) {
  setTimeout(() => {
    reduxStore.dispatch({
      type: 'history_change',
      payload: { action: 'init', location: window.location }
    })
  }, 1)
}

const persistor = persistStore(reduxStore)

if (isBrowser) {
  const load = fromEvent(window, 'load')
  const resize = fromEvent(window, 'resize')

  merge(load, resize)
    .pipe(
      map(() => getBreakPointFromWidth(window.innerWidth)),
      distinctUntilChanged(R.equals)
    )

    .subscribe(breakpoint =>
      reduxStore.dispatch({ type: 'set_screen_size', payload: breakpoint })
    )
}

if (isBrowser) {
  epicMiddleware.run(epics)
}

export { reduxStore }
export { persistor }
