import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Link as RouterLink } from '@reach/router'

import ifElse from 'src/functions/misc/ifElse'
import isBrowser from 'src/utils/isBrowser'

import './Link.scss'

const Link = ({ children, to, openAsModal, openModal, disabled, ...props }) => {
  const createCustomLink = () => (
    <a
      href={to}
      onClick={e => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        e.preventDefault()
        e.nativeEvent.preventDefault()
        openModal(to)
        return false
      }}
      {...props}
      styleName={`link ${disabled ? 'disabled' : ''}`}
      className={props.className}
    >
      {children}
    </a>
  )

  const useRouterLink = () => (
    <RouterLink to={to} {...props} styleName='link' className={props.className}>
      {children}
    </RouterLink>
  )

  return ifElse(isBrowser && openAsModal, createCustomLink, useRouterLink)
}

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired,

  to: PropTypes.string.isRequired,
  openAsModal: PropTypes.bool
}

const mapStateToProps = ({ modal }) => ({ modal })

const mapDispatchToProps = dispatch => ({
  openModal: to => {
    dispatch({ type: 'open_modal', payload: to })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Link)
