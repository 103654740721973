
import universal, { setHasBabelPlugin } from '/app/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../../app/src/pages/404.js'/* webpackChunkName: "..-..-app-src-pages-404" */), universalOptions)
const t_1 = universal(import('../../app/src/pages/about.js'/* webpackChunkName: "..-..-app-src-pages-about" */), universalOptions)
const t_2 = universal(import('../../app/src/pages/basket.js'/* webpackChunkName: "..-..-app-src-pages-basket" */), universalOptions)
const t_3 = universal(import('../../app/src/pages/checkout.js'/* webpackChunkName: "..-..-app-src-pages-checkout" */), universalOptions)
const t_4 = universal(import('../../app/src/pages/complete.js'/* webpackChunkName: "..-..-app-src-pages-complete" */), universalOptions)
const t_5 = universal(import('../../app/src/pages/index2.js'/* webpackChunkName: "..-..-app-src-pages-index2" */), universalOptions)
const t_6 = universal(import('../../app/src/pages/restart.js'/* webpackChunkName: "..-..-app-src-pages-restart" */), universalOptions)
const t_7 = universal(import('../../app/src/pages/select-time.js'/* webpackChunkName: "..-..-app-src-pages-select-time" */), universalOptions)
const t_8 = universal(import('../../app/src/dynamicPages/stores.js'/* webpackChunkName: "..-..-app-src-dynamicPages-stores" */), universalOptions)
const t_9 = universal(import('../../app/src/dynamicPages/store.js'/* webpackChunkName: "..-..-app-src-dynamicPages-store" */), universalOptions)
const t_10 = universal(import('../../app/src/dynamicPages/select-store.js'/* webpackChunkName: "..-..-app-src-dynamicPages-select-store" */), universalOptions)
const t_11 = universal(import('../../app/src/dynamicPages/categories.js'/* webpackChunkName: "..-..-app-src-dynamicPages-categories" */), universalOptions)
const t_12 = universal(import('../../app/src/dynamicPages/children/products.js'/* webpackChunkName: "..-..-app-src-dynamicPages-children-products" */), universalOptions)
const t_13 = universal(import('../../app/src/dynamicPages/children/product.js'/* webpackChunkName: "..-..-app-src-dynamicPages-children-product" */), universalOptions)


// Template Map
export default {
  '../../app/src/pages/404.js': t_0,
'../../app/src/pages/about.js': t_1,
'../../app/src/pages/basket.js': t_2,
'../../app/src/pages/checkout.js': t_3,
'../../app/src/pages/complete.js': t_4,
'../../app/src/pages/index2.js': t_5,
'../../app/src/pages/restart.js': t_6,
'../../app/src/pages/select-time.js': t_7,
'../../app/src/dynamicPages/stores.js': t_8,
'../../app/src/dynamicPages/store.js': t_9,
'../../app/src/dynamicPages/select-store.js': t_10,
'../../app/src/dynamicPages/categories.js': t_11,
'../../app/src/dynamicPages/children/products.js': t_12,
'../../app/src/dynamicPages/children/product.js': t_13
}

export const notFoundTemplate = "../../app/src/pages/404.js"
