import React from 'react'
import PropTypes from 'prop-types'

import condWith from 'src/functions/misc/condWith'
import isTrue from 'src/functions/boolean/isTrue'

import './Paragraph.scss'

const Paragraph = ({ children, leading, small, ...props }) => (
  <p
    styleName={`paragraph ${condWith(isTrue, [
      [leading, 'leading'],
      [small, 'small'],
      [true, '']
    ])}`}
    className={props.className}
    {...props}
  >
    {children}
  </p>
)

Paragraph.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired,

  leading: PropTypes.bool,
  small: PropTypes.bool
}

export default Paragraph
