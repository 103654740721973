import { ofType } from 'redux-observable'
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators'
import * as Observable from 'rxjs'
import { ajax } from 'rxjs/ajax'
import * as R from 'ramda'
import { dotPath } from '../functions/object/dotPath'
import ifElse from '../functions/misc/ifElse'

const onCheckoutAction = response => ({
  type: 'got_checkout_snippet',
  payload: response
})
const onFailAction = response => ({
  type: 'checkout_snippet_failed',
  payload: response
})

const onCheckoutResponse = response =>
  ifElse(R.prop('success'), onCheckoutAction, onFailAction, response)

export default (action$, state$) =>
  action$.pipe(
    ofType('get_checkout_snippet'),
    withLatestFrom(state$), // Get latest state
    mergeMap(([action, state]) => {
      const apiUrl = dotPath('siteData.checkoutApi')(state)
      const orderNumber = dotPath('cart.ecommerceCart.number')(state)
      const items = dotPath('cart.items')(state)

      let orderData = {}
      if (orderNumber) {
        orderData.orderNumber = orderNumber
      }

      return Observable.concat(
        Observable.of({
          type: 'pending_request',
          payload: {
            url: `${apiUrl}`,
            fetching: 'checkout',
            data: { items: JSON.stringify(items), ...orderData }
          }
        }),
        ajax
          .post(
            `${apiUrl}`,
            { items: JSON.stringify(items), ...orderData },
            {
              Accept: 'application/json'
            }
          )
          .pipe(
            map(ajaxResponse => onCheckoutResponse(ajaxResponse.response)),
            catchError(error =>
              Observable.of({
                type: 'checkout_snippet_error',
                payload: (console.log(error), error.xhr.response),
                error: true
              })
            )
          )
      )
    })
  )
