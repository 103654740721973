import React from 'react'
import { connect } from 'react-redux'
import { withSiteData } from 'react-static'

const ReduxSiteStoreComponent = React.memo(props => {
  const { updateSiteData, ...siteData } = props
  updateSiteData(siteData)
  return false
})

const mapDispatchToProps = dispatch => ({
  updateSiteData: props => {
    dispatch({ type: 'update_site_data', payload: props })
  }
})
export default withSiteData(
  connect(
    null,
    mapDispatchToProps
  )(ReduxSiteStoreComponent)
)
