import React from 'react'
import { Provider } from 'react-redux'
import { Head, Root } from 'react-static'
import { Router } from '@reach/router'
import { PersistGate } from 'redux-persist/integration/react'
import { reduxStore, persistor } from './stores/reduxStore'

import Menu from './containers/Menu/Menu'
import ModalContainer from './containers/ModalContainer'
import ReduxSiteStoreComponentWithSiteData from './containers/ReduxSiteStoreComponentWithSiteData'

import { getDynamicRoutes } from 'src/dynamicRoutes/dynamic.config'

import DynamicRoutes from './containers/DynamicRoutes/DynamicRoutes'
import StaticRoutes from './containers/StaticRoutes/StaticRoutes'
import Footer from './components/general/Footer/Footer'

import './app.scss'

const isBrowser = typeof window !== 'undefined'

const renderRoot = (
  <Root>
    <Head>
      <meta charSet='UTF-8' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />
      <title>Pressbyrån Shop</title>
    </Head>
    <nav>
      <Menu />
    </nav>

    <div styleName='content-wrap'>
      <div styleName='content' className='content'>
        <Router>
          <DynamicRoutes path='/d/*' dynamicRoutes={getDynamicRoutes()} />
          <StaticRoutes default />
        </Router>
        <ModalContainer />
      </div>
      <Footer />
    </div>
  </Root>
)

const App = () => (
  <Provider store={reduxStore}>
    <ReduxSiteStoreComponentWithSiteData />
    {renderRoot}
  </Provider>
)
const BrowserApp = () => (
  <Provider store={reduxStore}>
    <PersistGate loading={null} persistor={persistor}>
      <ReduxSiteStoreComponentWithSiteData />
      {renderRoot}
    </PersistGate>
  </Provider>
)

export default (isBrowser ? BrowserApp : App)
