import { ofType } from 'redux-observable'
import {
  delay,
  take,
  skip,
  publish,
  buffer,
  catchError,
  filter,
  map,
  mergeMap,
  withLatestFrom
} from 'rxjs/operators'
import * as Observable from 'rxjs'
import { ajax } from 'rxjs/ajax'
import * as R from 'ramda'
import { dotPath } from '../functions/object/dotPath'
import ifElse from '../functions/misc/ifElse'
import isUndefined from '../functions/boolean/isUndefined'

const onCartAction = response => ({
  type: 'added_to_basket',
  payload: response.cart
})
const onFailAction = response => ({
  type: 'added_to_basket_failed',
  payload: response
})

const onCartResponse = response =>
  ifElse(R.prop('success'), onCartAction, onFailAction, response)

const mergePurchasables = R.reduce(
  R.mergeWith((a, b) => {
    const addOnMerge = (k, l, r) => (k === 'amount' ? R.add(l, r) : r)
    return R.mergeDeepWithKey(addOnMerge, a, b)
  }),
  {}
)

const getPurchasablesFromActions = (actions, cartItems) => {
  const actionItems = actions.map(action => {
    const amount = dotPath('payload.amount')(action)
    const purchasableId = dotPath('payload.purchasableId')(action)

    return { [purchasableId]: { id: purchasableId, amount } }
  })
  const mergedPurchasables = mergePurchasables(actionItems)

  const withCartItems = R.map(p => {
    // const qty = (cartItems[p.id] && cartItems[p.id].amount + p.amount) || p.amount
    // Only use the amount from the cart (that has already been updated
    return { id: p.id, qty: cartItems[p.id] ? cartItems[p.id].amount : 0 }
  })(mergedPurchasables)

  return withCartItems
}

let handleBasketAction = function (state, actions) {
  const apiUrl = dotPath('siteData.basketApi')(state)
  const orderNumber = dotPath('cart.ecommerceCart.number')(state)
  const cartItems = dotPath('cart.items')(state)
  const purchasables = getPurchasablesFromActions(actions, cartItems)
  // const qty = (cartItems[purchasableId] && cartItems[purchasableId].amount + amount) || amount

  const orderObject = isUndefined(orderNumber) ? {} : { orderNumber } // use this hack to append object only if defined

  return Observable.concat(
    Observable.of({
      type: 'pending_request',
      payload: {
        url: `${apiUrl}`,
        fetching: 'cart',
        data: { purchasables: JSON.stringify(purchasables), ...orderObject }
      }
    }),
    ajax
      .post(
        `${apiUrl}`,
        { purchasables: JSON.stringify(purchasables), ...orderObject },
        { Accept: 'application/json' }
      )
      .pipe(
        map(ajaxResponse => onCartResponse(ajaxResponse.response)),
        catchError(error =>
          Observable.of({
            type: 'add_to_basket_error',
            payload: (console.log(error), error),
            error: true
          })
        )
      )
  )
}
export default (action$, state$) => {
  const source = action$.pipe(
    ofType('add_to_basket'),
    publish()
  )

  // source$: Observable<T>
  const pubSource$ = Observable.merge(
    source.pipe(
      take(1),
      map(first => [first]),
      delay(50)
    ),
    source.pipe(
      skip(1),
      buffer(Observable.interval(1000))
    )
  )
  source.connect()
  return pubSource$.pipe(
    filter(a => a.length),
    withLatestFrom(state$), // Get latest state
    mergeMap(([actions, state]) => {
      return handleBasketAction(state, actions)
    })
  )
}
