import { combineReducers } from 'redux'
// import storage from 'redux-persist/lib/storage' // defaults to localStorage for web and AsyncStorage for react-native
import storage from 'redux-persist/lib/storage/session'
import { createMigrate, persistReducer } from 'redux-persist'

import breakpoint from './breakpoint'
import modal from './modal'
import selectedData from './selectedData'
import siteData from './siteData'
import cart from './cart'
import checkout from './checkout'
import fetchedReducer from './fetchedReducer'
import pendingRequestStatus from './pendingRequestStatus'
import { showBanner } from './banner'

const persistCartConfig = {
  key: 'cart',
  storage,
  blacklist: ['errors']
}

const isBrowser = typeof window !== 'undefined'
const persistedCartReducer = isBrowser
  ? persistReducer(persistCartConfig, cart)
  : cart

const selectedDataMigrations = {
  1: state => {
    // migration to keep only device state
    return {
      pickupTime: '',
      storeData: {}
    }
  }
}

const selectedDataPersistConfig = {
  key: 'selectedData',
  version: 1,
  storage,
  migrate: createMigrate(selectedDataMigrations, { debug: false })
}

// const persistedPickupTimeReducer = persistReducer({key:'pickupTime',storage}, pickupTime)
const persistedSelectedDataReducer = isBrowser
  ? persistReducer(selectedDataPersistConfig, selectedData)
  : selectedData

export default combineReducers({
  siteData,
  modal,
  selectedData: persistedSelectedDataReducer,
  breakpoint,
  cart: persistedCartReducer,
  checkout,
  fetched: fetchedReducer,
  pendingRequestStatus,
  showBanner
})
