import * as R from 'ramda'
import hash from 'object-hash'

import createReducer from '../functions/misc/createReducer'

const getHash = props => {
  const { path, method, data } = props
  return hash({ path, method, data })
}

const addPathAndRespnoseToKeyValue = payload => ({
  [getHash(payload.fetch)]: payload.response
})
const mergePathAndResponseWithState = (payload, state) =>
  R.merge(addPathAndRespnoseToKeyValue(payload), state)

export default createReducer({})({
  // prefetch: identity,
  fetched: mergePathAndResponseWithState
})
