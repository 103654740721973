import { ofType, combineEpics } from 'redux-observable'
import { map, filter, withLatestFrom } from 'rxjs/operators'
import * as R from 'ramda'
import { dotPath } from '../functions/object/dotPath'

const startsWith = R.curry((prefix, xs) =>
  R.equals(R.take(prefix.length, xs), prefix)
)

export default combineEpics(
  (action$, state$) =>
    action$.pipe(
      ofType('history_change'),
      map(action => ({
        type: 'gtmEvent',
        payload: {
          event: 'hitType',
          page: action.payload.location.pathname,
          data: action.payload
        }
      }))
    ),
  (action$, state$) =>
    action$.pipe(
      ofType('open_modal'),
      map(action => ({
        type: 'gtmEvent',
        payload: {
          event: 'hitType',
          page: action.payload,
          data: action.payload
        }
      }))
    ),
  (action$, state$) =>
    action$.pipe(
      ofType('history_change'),
      filter(action =>
        R.pathSatisfies(
          R.anyPass([startsWith('/basket'), startsWith('/checkout')]),
          ['payload', 'location', 'pathname'],
          action
        )
      ),
      withLatestFrom(state$), // Get latest state
      map(([action, state]) => ({
        type: 'basket_view',
        payload: {
          viewType: R.cond([
            [startsWith('/basket'), R.always('checkout')],
            [startsWith('/checkout'), R.always('payment')]
          ])(dotPath('payload.location.pathname')(action)),
          pathname: action.payload.location.pathname,
          data: action.payload,
          state: state
        }
      }))
    ),
  (action$, state$) =>
    action$.pipe(
      filter(action => action.type !== 'gtmEvent'),
      map(action => ({
        type: 'gtmEvent',
        payload: {
          event: action.type,
          data: action.payload
        }
      }))
    )
)
