import React from 'react'
import PropTypes from 'prop-types'

const Icon = props => {
  const icons = Object.keys(props).filter(k => props[k])
  const iconName = icons.length ? icons[icons.length - 1] : ''
  return <span className={'icon-icon-' + iconName} />
}

Icon.propTypes = {
  'angle-right': PropTypes.bool,
  bag: PropTypes.bool,
  'close-filled': PropTypes.bool,
  close: PropTypes.bool,
  crosshair: PropTypes.bool,
  date: PropTypes.bool,
  magnifier: PropTypes.bool,
  men: PropTypes.bool,
  pin: PropTypes.bool,
  tick: PropTypes.bool,
  'time-filled': PropTypes.bool,
  time: PropTypes.bool
}

export default Icon
