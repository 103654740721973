import { always, identity } from 'ramda'

import createReducer from 'src/functions/misc/createReducer'

const initialState = false

export default createReducer(initialState)({
  pending_request: identity,
  add_to_basket: always(true),
  added_to_basket: always(initialState),
  add_to_basket_error: always(initialState),
  checkout_snippet_error: always(initialState),
  added_to_basket_failed: always(initialState)
})
