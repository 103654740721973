import React from 'react'

export default function Spinner () {
  return (
    <div
      className='spinner'
      style={{
        display: 'inline-block',
        width: '',
        textAlign: 'center',
        padding: '10px'
        // transform: 'scale'
      }}
    >
      <style>
        {`
        @keyframes spinner {
          0% {
            transform: rotate(0deg)
          }
          100% {
            transform: rotate(360deg)
          }
        }
      `}
      </style>
      <svg
        style={{
          width: '50px',
          height: '50px'
        }}
      >
        <circle
          style={{
            transformOrigin: '50% 50% 0px',
            animation: 'spinner 1s infinite',
            r: 20,
            stroke: 'rgba(0,0,0,0.4)',
            strokeWidth: 4,
            cx: 25,
            cy: 25,
            strokeDasharray: 10.4,
            strokeLinecap: 'round',
            fill: 'transparent'
          }}
        />
      </svg>
    </div>
  )
}
