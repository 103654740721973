import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ifElse from 'src/functions/misc/ifElse'
import { LazyLoadImage } from 'react-lazy-load-image-component'

class Image extends Component {
  static defaultProps = {
    lazyLoad: true
  }

  render (...args) {
    return ifElse(
      this.props.lazyLoad,
      this.renderLazyLoad.bind(this),
      this.renderDirectLoad.bind(this),
      ...args
    )
  }

  renderDirectLoad () {
    const { lazyLoad, ...directProps } = this.props
    return <img {...directProps} />
  }

  renderLazyLoad () {
    // Remove attributes that is not to be rendered
    const { lazyLoad, ...lazyProps } = this.props
    return <LazyLoadImage {...lazyProps} />
  }
}

Image.propTypes = {
  lazyLoad: PropTypes.bool,
  src: PropTypes.string.isRequired
}

export default Image
