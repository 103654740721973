import { compose, find, head, last, call, identity } from 'ramda'

import isTruthy from 'src/functions/boolean/isTruthy'
import isFunction from 'src/functions/boolean/isFunction'
import ifElse from './ifElse'
import when from './when'

export default (condFn, conditions) => {
  const trueCondition = find(
    compose(
      condFn,
      head
    ),
    conditions
  )

  const conditionValue = () => when(isTruthy, last, trueCondition)

  return ifElse(isFunction, call, identity, conditionValue)
}
