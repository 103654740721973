import React from 'react'
import {
  prefetch,
  routeErrorByPath,
  routeInfoByPath,
  Spinner,
  templatesByPath,
  getRoutePath
} from 'react-static'

let instances = []

global.reloadAll = () => {
  instances.forEach(instance => instance.safeForceUpdate())
}
const RoutePathContext = React.createContext()

class VirtualRouteData extends React.Component {
  static defaultProps = {
    Loader: Spinner
  }

  componentDidMount () {
    instances.push(this)
  }

  componentWillUnmount () {
    instances = instances.filter(d => d !== this)
    this.unmounted = true
  }

  safeForceUpdate = () => {
    if (this.unmounted) {
      return
    }
    this.forceUpdate()
  }

  render () {
    const { children, routePath } = this.props

    const fixedRoutePath = getRoutePath(routePath)

    const routeError = routeErrorByPath[fixedRoutePath]
    const routeInfo = routeError
      ? routeInfoByPath['404']
      : routeInfoByPath[fixedRoutePath]

    // If there was an error reported for this path, throw an error
    // unless there is data for the 404 page
    if (routeError && (!routeInfo || !routeInfo.data)) {
      throw new Error(
        `React-Static: <VirtualRouteData> could not find any data for this route: ${fixedRoutePath}. If this is a dynamic route, please remove any reliance on RouteData or withRouteData from this routes components`
      )
    }

    // If we haven't requested the routeInfo and its shared data yet, or it's loading
    // Show a spinner and prefetch the data
    // TODO:suspense - This will become a suspense resource
    if (shouldLoadData(routeInfo)) {
      ;(async () => {
        await Promise.all([
          prefetch(fixedRoutePath, { priority: true }),
          new Promise(resolve =>
            setTimeout(resolve, process.env.REACT_STATIC_MIN_LOAD_TIME)
          )
        ])
        // this.safeForceUpdate()
      })()

      return <Spinner />
    }

    // Otherwise, get it from the routeInfoByPath (subsequent client side)
    // and merge it with the shared data
    const fullData = {
      ...routeInfo.data,
      ...routeInfo.sharedData,
      Comp: templatesByPath[fixedRoutePath]
    }
    return (
      <RoutePathContext.Provider value={fixedRoutePath}>
        {children(fullData)}
      </RoutePathContext.Provider>
    )
  }
}

function shouldLoadData (routeInfo) {
  if (!routeInfo || !routeInfo.data) {
    return true
  }

  return shouldLoadSharedData(routeInfo)
}

function shouldLoadSharedData (routeInfo) {
  return hasPropHashes(routeInfo) && !routeInfo.sharedData
}

function hasPropHashes (routeInfo) {
  return (
    routeInfo.sharedHashesByProp &&
    Object.keys(routeInfo.sharedHashesByProp).length > 0
  )
}

export default VirtualRouteData

export function withVirtualRouteData (Comp, opts = {}) {
  return props => (
    <VirtualRouteData {...opts}>
      {routeData => (
        <RoutePathContext.Provider value={'stores'}>
          <Comp {...routeData} {...props} />
        </RoutePathContext.Provider>
      )}
    </VirtualRouteData>
  )
}

export const withRoutePathContext = Comp => props => (
  <RoutePathContext.Consumer>
    {routePath => <Comp {...props} routePath={routePath} />}
  </RoutePathContext.Consumer>
)
