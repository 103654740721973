import { identity } from 'ramda'

import createReducer from 'src/functions/misc/createReducer'
import isBrowser from '../utils/isBrowser'
import { getBreakPointFromWidth } from '../helpers/breakpoint'

export default createReducer(
  (isBrowser && getBreakPointFromWidth(window.innerWidth)) || 'mobile'
)({
  set_screen_size: identity
})
