import React from 'react'
import PropTypes from 'prop-types'

import ifElse from 'src/functions/misc/ifElse'
import isTrue from 'src/functions/boolean/isTrue'
import condWith from 'src/functions/misc/condWith'

import './Label.scss'

const Label = ({
  children,
  large,
  medium,
  small,
  cart,
  link,
  span,
  ...props
}) =>
  ifElse(
    span,
    <span
      styleName={`label ${condWith(isTrue, [
        [large, 'large'],
        [small, 'small'],
        [cart, 'cart'],
        [link, 'link'],
        [true, 'medium']
      ])}`}
      className={props.className}
      {...props}
    >
      {children}
    </span>,
    <p
      styleName={`label ${condWith(isTrue, [
        [large, 'large'],
        [small, 'small'],
        [cart, 'cart'],
        [link, 'link'],
        [true, 'medium']
      ])}`}
      className={props.className}
      {...props}
    >
      {children}
    </p>
  )

Label.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.array
  ]).isRequired,

  large: PropTypes.bool,
  medium: PropTypes.bool,
  small: PropTypes.bool,
  cart: PropTypes.bool,
  span: PropTypes.bool
}

export default Label
