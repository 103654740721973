import React from 'react'
import * as R from 'ramda'

import Fetch from '../../components/general/Fetch/Fetch'
import DynamicRoutes from './DynamicRoutes'
import ifElse from '../../functions/misc/ifElse'
import isFunction from '../../functions/boolean/isFunction'
import isUndefined from '../../functions/boolean/isUndefined'
import RenderSpinnerOrChild from '../../components/general/RenderSpinnerOrChild/RenderSpinnerOrChild'

const DynamicRoute = props => {
  const { routeProps, comp: Comp, parentData, ...nestedProps } = props
  const { dynamicDataPath, getData, getChildren } = routeProps // Properties from dynamic.config.js
  const { location, navigate } = nestedProps
  const renderChildComp = fetchData => {
    return (
      <Comp
        dynamicData={fetchData}
        routeProps={routeProps}
        location={location}
        navigate={navigate}
      >
        {getChildren && (
          <DynamicRoutes
            parentData={parentData}
            dynamicRoutes={getChildren(fetchData)}
          />
        )}
      </Comp>
    )
  }
  if (dynamicDataPath) {
    return (
      <Fetch
        observer={false}
        path={ifElse(isFunction, dynamicDataPath, dynamicDataPath, parentData)}
      >
        {fetchedData => (
          <RenderSpinnerOrChild hasData={fetchedData !== false}>
            {renderChildComp(
              ifElse(!isUndefined(getData), getData, R.identity, fetchedData)
            )}
          </RenderSpinnerOrChild>
        )}
      </Fetch>
    )
  } else {
    return renderChildComp({})
  }
}

export default DynamicRoute
