import React, { Component } from 'react'

import PropTypes from 'prop-types'
import Spinner from '../Spinner/Spinner'

import './RenderSpinnerOrChild.scss'

class RenderSpinnerOrChild extends Component {
  static propTypes = {
    hasData: PropTypes.bool.isRequired,
    fullPage: PropTypes.bool,
    minimumTimeToDisplayLoader: PropTypes.number,
    minimumTimeToLoad: PropTypes.number,
    maximumTimeToLoad: PropTypes.number
  }
  static defaultProps = {
    fullPage: false,
    minimumTimeToDisplayLoader: 100,
    minimumTimeToLoad: 250,
    maximumTimeToLoad: 20000
  }
  state = {
    display: false,
    load: false,
    timeout: false
  }

  constructor (props) {
    const {
      minimumTimeToDisplayLoader,
      minimumTimeToLoad,
      maximumTimeToLoad
    } = props
    setTimeout(() => {
      this.setState({ display: true })
    }, minimumTimeToDisplayLoader)

    setTimeout(() => {
      this.setState({ load: true })
    }, minimumTimeToLoad)

    setTimeout(() => {
      this.setState({ timeout: true })
    }, maximumTimeToLoad)
    super(props)
  }

  render () {
    const { display, load, timeout } = this.state
    const { children, hasData, fullPage } = this.props

    const renderLoaderOrChild = fetchedData => {
      const waitedLongEnoughToDisplayAndGotData =
        timeout || (load && fetchedData)
      const gotDataBeforeStartedToWait = fetchedData && !display
      if (
        fetchedData ||
        waitedLongEnoughToDisplayAndGotData ||
        gotDataBeforeStartedToWait
      ) {
        return <React.Fragment>{children}</React.Fragment>
      }
      return (
        <div styleName={fullPage ? 'fullpage' : ''}>
          <Spinner />
        </div>
      )
    }

    return renderLoaderOrChild(hasData)
  }
}

export default RenderSpinnerOrChild
