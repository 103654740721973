import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { InView } from 'react-intersection-observer'
import normalizeUrl from 'normalize-url'
import hash from 'object-hash'
import * as R from 'ramda'

import { fetchAction } from 'src/actions/prefetch'
import ifElse from 'src/functions/misc/ifElse'
import isFunction from 'src/functions/boolean/isFunction'

function validURL (str) {
  var pattern = new RegExp('^(https?:\\/\\/)', 'i') // fragment locator
  return str && !!pattern.test(str)
}

const isBrowser = typeof window !== 'undefined'
const absoluteUrl = isBrowser
  ? window.location.origin + '/'
  : 'http://localhost/'
const normalizePath = path =>
  R.tryCatch(
    path =>
      path
        ? validURL(path)
          ? normalizeUrl(path)
          : normalizeUrl(absoluteUrl + path)
        : null,
    error => {
      console.error('Not a valid url', path, error)
    }
  )(path)
const getHash = props => {
  const { path, method = 'get', data = {} } = props
  const routePath = normalizePath(path)
  return hash({ path: routePath, method, data })
}

class Fetch extends Component {
  static propTypes = {
    fetch: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    force: PropTypes.bool,
    observer: PropTypes.bool
  }

  static defaultProps = {
    force: false,
    observer: true,
    method: 'get',
    data: {}
  }

  static state = {
    inView: false
  }

  constructor (props) {
    super(props)
    this.state = {
      inView: false
    }
    this.isThisFetched = this.isThisFetched.bind(this)
    this.fetchIfThisShouldFetch = this.fetchIfThisShouldFetch.bind(this)
  }

  componentDidCatch (error, info) {
    console.error('got error', error, info)
  }

  isThisFetched () {
    const { fetched } = this.props
    return ifElse(fetched[getHash(this.props)], true, false)
  }

  // shouldComponentUpdate () {
  //   // return !this.isThisFetched()
  // }

  fetchIfThisShouldFetch () {
    const { inView } = this.state

    if (!isBrowser) {
      return false
    }
    const { path, method, data, force, fetch } = this.props
    const routePath = normalizePath(path)
    ifElse(inView || force, fetch, false, { path: routePath, method, data })
  }

  componentDidUpdate () {
    this.fetchIfThisShouldFetch() // Trigger fetch
  }

  componentDidMount () {
    this.fetchIfThisShouldFetch() // Trigger fetch
  }

  render () {
    const { children, fetched } = this.props

    const getData = fetched[getHash(this.props)] || false
    const renderChildrenFunction = () =>
      isFunction(children) ? children(getData) : false

    const renderChildrenWithObserver = () => (
      <InView
        onChange={inView => {
          this.setState({ inView: inView })
        }}
        triggerOnce
      >
        {renderChildrenFunction()}
      </InView>
    )
    const renderChildrenDirect = () => (
      <React.Fragment>{renderChildrenFunction()}</React.Fragment>
    )

    return ifElse(
      this.isThisFetched && this.observer,
      renderChildrenDirect,
      renderChildrenWithObserver
    )
  }
}

const mapStateToProps = ({ fetched }) => ({ fetched })

const mapDispatchToProps = dispatch => ({
  fetch: fetchAction(dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fetch)
