import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import isBrowser from 'src/utils/isBrowser'

import './Modal.scss'
import Icon from '../general/Icon/Icon'

class Modal extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
      .isRequired,
    closeModal: PropTypes.func.isRequired,
    closeCallback: PropTypes.func
  }

  static defaultProps = {}

  closeModalOnEsc = ({ keyCode }) => {
    if (keyCode === 27) {
      this.props.closeModal()
    }
  }

  componentDidMount () {
    if (isBrowser) {
      document.addEventListener('keydown', this.closeModalOnEsc, false)
    }
  }

  componentWillUnmount () {
    if (isBrowser) {
      document.removeEventListener('keydown', this.closeModalOnEsc, false)
    }
  }

  render () {
    const { children, closeModal, closeCallback } = this.props
    const closeModalCallback = closeCallback || closeModal

    return (
      <div styleName='modal' onClick={closeModalCallback}>
        <div
          styleName='modal-container'
          onClick={event => event.stopPropagation()}
        >
          <span onClick={closeModalCallback} styleName='close-button'>
            <Icon close-filled />
          </span>

          {children}
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  closeModal: () => {
    dispatch({ type: 'close_modal' })
  }
})

export default connect(
  null,
  mapDispatchToProps
)(Modal)
