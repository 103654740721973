import { compose, identical, type } from 'ramda'

// Object
// Number
// Boolean
// String
// Null
// Array
// RegExp
// Function
// Undefined

export default theType =>
  compose(
    identical(theType),
    type
  )
