// Imports


// Plugins
const plugins = [{
  location: "/app/node_modules/react-static-plugin-css-modules",
  plugins: [],
  hooks: {}
},
{
  location: "/app/node_modules/react-static-plugin-sass",
  plugins: [],
  hooks: {}
},
{
  location: "/app/plugins/fixForGCP",
  plugins: [],
  hooks: {}
},
{
  location: "/app/plugins/fixForSassToModules",
  plugins: [],
  hooks: {}
},
{
  location: "/app",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins