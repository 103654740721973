import React from 'react'
import { connect } from 'react-redux'
import Label from '../Label/Label'
import Paragraph from '../Paragraph/Paragraph'
import * as R from 'ramda'

import isArray from 'src/functions/boolean/isArray'

import './Footer.scss'
import isUndefined from '../../../functions/boolean/isUndefined'
import Image from '../Image/Image'

const Footer = ({ siteData, cart }) => {
  if (!siteData.footer || !isArray(siteData.footer)) {
    return false
  }

  const footerComponents = R.flatten(siteData.footer).map((node, key) => {
    const textWithBr =
      node.text &&
      node.text.split('\n').map((item, key) => {
        return (
          <React.Fragment key={key}>
            {item}
            <br />
          </React.Fragment>
        )
      })

    return node.type === 'link' ? (
      <Label key={key} large>
        <a href={node.href} target='_blank'>
          {textWithBr}
        </a>
      </Label>
    ) : node.type === 'text' ? (
      <div key={key}>
        <Paragraph small>{textWithBr}</Paragraph>
      </div>
    ) : (
      <div key={key}>
        <a href={node.href}>
          <Image src={node.image['thumb'] || node.image['default']} />
        </a>
      </div>
    )
  })

  const hasCart = !R.anyPass([
    R.isEmpty,
    isUndefined,
    R.compose(
      R.isEmpty,
      R.keys,
      R.prop('items')
    )
  ])(cart)

  const extraPaddingStyle = !hasCart ? '' : ' extra-padding'

  return (
    <footer styleName={'footer'}>
      <div styleName='wrap'>
        <div styleName='content'>{footerComponents}</div>
      </div>
      <div styleName={extraPaddingStyle} />
    </footer>
  )
}

const mapStateToProps = ({ siteData, cart }) => ({
  siteData,
  cart
})

export default connect(
  mapStateToProps,
  null
)(Footer)
