import React from 'react'
import { RouteData, Routes } from 'react-static'
import isString from '../../functions/boolean/isString'
import ifElse from '../../functions/misc/ifElse'
import Fetch from '../../components/general/Fetch/Fetch'
import RenderSpinnerOrChild from '../../components/general/RenderSpinnerOrChild/RenderSpinnerOrChild'

const isBrowser = typeof window !== 'undefined'

export default () => (
  <Routes default>
    {({ routePath, getComponentForPath }) => {
      const Comp = getComponentForPath(routePath)
      return (
        <RouteData>
          {({ dynamicDataPath, ...data }) =>
            ifElse(
              isString(dynamicDataPath) && isBrowser,
              <Fetch path={dynamicDataPath}>
                {dynamicData => {
                  return (
                    <RenderSpinnerOrChild
                      hasData={dynamicData !== false}
                      maximumTimeToLoad={1000}
                      fullPage
                    >
                      <Comp {...data} {...dynamicData} />
                    </RenderSpinnerOrChild>
                  )
                }}
              </Fetch>,
              <Comp {...data} />
            )
          }
        </RouteData>
      )
    }}
  </Routes>
)
