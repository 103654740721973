import { combineEpics } from 'redux-observable'

import basket from './basket'
import cart from './cart'
import removeFromCart from './removeFromCart'
import checkout from './checkout'
import dynamicData from './dynamicData'
import googleTagManager from './googleTagManager'

export default combineEpics(
  basket,
  cart,
  removeFromCart,
  checkout,
  dynamicData,
  googleTagManager
)
