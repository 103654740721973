import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { globalHistory } from '@reach/router'

import ifElse from 'src/functions/misc/ifElse'
import isBrowser from 'src/utils/isBrowser'

import Modal from 'src/components/modal/Modal'
import VirtualRouteData from './VirtualRouteData'

class ModalContainer extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  }

  static defaultProps = {
    history: globalHistory
  }

  state = {
    context: this.getContext(),
    refs: { unlisten: null }
  }

  getContext () {
    const {
      props: {
        history: { navigate, location }
      }
    } = this
    return { navigate, location }
  }

  componentDidMount () {
    const {
      state: { refs },
      props: { history }
    } = this
    refs.unlisten = history.listen(() => {
      Promise.resolve().then(() => {
        // TODO: replace rAF with react deferred update API when it's ready https://github.com/facebook/react/issues/13306
        ifElse(
          isBrowser && window,
          isBrowser && window.requestAnimationFrame,
          cb => {
            cb()
          },
          () => () => {
            if (!this.unmounted) {
              this.setState(() => ({ context: this.getContext() }))
              this.props.closeModal()
            }
          }
        )
      })
    })
  }

  componentWillUnmount () {
    const {
      state: { refs }
    } = this
    this.unmounted = true
    refs.unlisten()
  }

  render () {
    if (!this.props.modal) {
      return false
    }
    return (
      <Modal>
        <VirtualRouteData routePath={this.props.modal}>
          {({ Comp, ...data }) => <Comp {...data} />}
        </VirtualRouteData>
      </Modal>
    )
  }
}

const mapStateToProps = ({ modal }) => ({ modal })

const mapDispatchToProps = dispatch => ({
  closeModal: () => {
    dispatch({ type: 'close_modal' })
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalContainer)
