import isFunction from 'src/functions/boolean/isFunction'

export default (cond, truthy, falsy, val) => {
  const value = isFunction(val) ? val() : val
  const condition = isFunction(cond) ? cond(value) : cond

  return condition
    ? isFunction(truthy)
      ? truthy(value)
      : truthy
    : isFunction(falsy)
    ? falsy(value) /* eslint-disable-line */
    : falsy /* eslint-disable-line */
}
